

import img1 from '../images/icon/manager.png'
import img2 from '../images/icon/Lock.png'
import img3 from '../images/icon/smartphone.png'
const dataServices2 = [
    {
        id: 1,
        icon: img1,
        title: 'Manage your portfolio',
        text: 'Invest in stocks, bonds, options, crypto, and more in one place',
        active: 'active',
        bg:''
    },
    {
        id: 2,
        icon: img2,
        title: 'Fractional bonds. Exclusively on Public.',
        text: 'Access detailed financial data for thousands of bonds.Invest for as little as $100 and in any dollar amount.',
        active: '',
        bg:'green'
    },
    {
        id: 3,
        icon: img3,
        title: 'Mobile apps',
        text: 'Stay On Top Of The Markets With The Cryptolly App For Android Or IOS.',
        active: '',
        bg:'blue'
    },
   
]

export default dataServices2;